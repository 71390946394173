.Amplify-widget {
	position: relative;
	width: 300px;
	height: 150px;
}

.Amplify-widget__wrapper {
	width: 100%;
}

.Amplify-widget__header { 
	width: 125px;
	margin-left: auto;
	background-color: #1e4659;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	height: 15px;
	line-height: 15px;
}

.Amplify-widget__main {
	height: 135px;
	background-color: #f5f5f5;
	border: 1px solid #d8d6d6;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	box-sizing: border-box;
}

.Amplify-logo {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.Amplify-logo__text {
	color: #fff;
	font-size: 10px;
}

.Amplify-logo__img {
	height: 8px;
	pointer-events: none;
	margin-right: 5px;
	margin-left: 5px;
}

.GoogleReview {
	box-sizing: border-box;
	border-radius: 4px;
	width: 300px;
	height: 100%;
	padding: 10px 10px;
}

.GoogleReview__header {
	display: flex;
	justify-content: space-between;
}

.GoogleReview__name {
	font-size: 12px;
	font-weight: 700;
	margin: 0 0 4px;
}

.GoogleReview__review_date {
	font-size: 10px;
	color: #999;
	margin: 0 0 10px;
}

.GoogleReview__review_text {
	height: 58px;
	overflow-y: scroll;
}

.GoogleReview__review_text p {
	font-size: 10px;
	line-height: 13px;
	margin: 0;
	text-overflow: ellipsis;
}

.GoogleReview__review_text {
	font-size: 10px;
	margin: 0;
}

.GoogleReview__rating {
	display: flex;
	min-width: 75px;
}

.GoogleReview__rating img {
	width: 15px;
	height: 15px;
}
